import ForwardIcon from "../assets/common/ForwardIcon.svg";
import BillIcon from "../assets/common/BillIcon.svg";
import ReturnIcon from "../assets/common/ReturnIcon.svg";
import { useContext } from "react";
import { DataContext } from "../App";
import "./FormsComponent.css";
import { FormTitle } from "./common/FormTitle";

function EnergyHistory({ energyData, setEnergyData, choice, setChoice }: any) {
  const { setCount, count } = useContext(DataContext);
  const handleSelectDropdown = (data: any) => {
    setChoice(data.target.value);
  };

  return (
    <div className="form-container">
      <form className="quotation-form">
        <FormTitle
          subtitleBold={"Energy Historical"}
          subtitle={"Choose your input energy historical."}
        />
        <div className="formrow">
          <div className="content">
            <p className="font">Select your energy historical</p>
          </div>
          <form>
            <select
              className="select"
              name="bill"
              id="bill"
              onChange={handleSelectDropdown}
            >
              <option
                value="Average monthly Electricity Bill [baht]"
                label="Average monthly Electricity Bill"
              ></option>
              <option
                value="Average monthly electricity consumption [kWh]"
                label="Average monthly electricity consumption"
              ></option>
              <option
                value="Estimate monthly assumption [kWh]"
                label="Estimate monthly assumption"
              ></option>
            </select>
          </form>
        </div>
        <div className="formrow">
          <div className="content">
            <img src={BillIcon} alt="" style={{ marginRight: "16px" }} />
            <p className="font">{choice}</p>
          </div>
          <form>
            <input
              className="input"
              type="number"
              placeholder={choice}
              onChange={(e) => setEnergyData(e.target.value)}
            />
          </form>
        </div>
      </form>
      <div className="box">
        <div className="itemstart">
          <button
            type="button"
            className="but"
            onClick={() => setCount(count - 1)}
          >
            <img src={ReturnIcon} alt="" />
            <p className="next">back</p>
          </button>
        </div>
        <div className="itemend">
          <button
            type="button"
            className="but"
            onClick={() => setCount(count + 1)}
          >
            <p className="next">next</p>
            <img src={ForwardIcon} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default EnergyHistory;
