import React from "react";
import "../FormsComponent.css";

export const FormTitle = ({ subtitleBold, subtitle }: any) => {
  return (
    <div className="topic-container">
      <h3 className="topic-title">Quotation</h3>
      <p className="sub-title-bold">{subtitleBold}</p>
      <p className="sub-title">{subtitle}</p>
    </div>
  );
};
