import "./FormsComponent.css";
// We will the path of the icon we imported
import TitleIcon from "../assets/common/TitleIcon.svg";
import RoomIcon from "../assets/common/RoomIcon.svg";
import FloorIcon from "../assets/common/FloorIcon.svg";
import ApplicationIcon from "../assets/common/ApplicationIcon.svg";
import ForwardIcon from "../assets/common/ForwardIcon.svg";
import { useContext, useEffect, useState } from "react";
import { DataContext } from "../App";
import { FormTitle } from "./common/FormTitle";
import "./FormsComponent.css";
import { useForm } from "react-hook-form";

type FormListProps = {
  key: string;
  title: string;
  placeholder: string;
  iconPath: string;
};

type FormProps = {
  dataKey: string;
  title: string;
  placeholder: string;
  iconPath: string;
  data: any;
  setData: any;
  register: any;
  errors: any;
};

function Form({ dataKey, title, placeholder, iconPath, data, setData, register, errors }: FormProps) {
  const handleOnChange = (val: number | string) => {
    const newValue = { ...data };
    newValue[dataKey] = val;
    setData(newValue);
  };
  console.log(data);
  const [formError, setFormError] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setFormError(validate(data));
    setIsSubmit(true);
  };

  useEffect(() => {
    console.log(formError);
    if (Object.keys(formError).length === 0 && isSubmit) {
      console.log(data);
    }
  }, [formError]);

  const validate = (values: any) => {
    const error: any = {};
    if (!values.commonSpace) {
      error.commonSpace = "Common space is required!";
    }
    if (!values.entrance) {
      error.entrance = "Entrance is required!";
    }
    if (!values.guestRoom) {
      error.guestRoom = "Guest Room is required!";
    }
    if (!values.noOfFloor) {
      error.noOfFloor = "Number of floor is required!";
    }
    return error;
  };

  return (
    <div className="formrow">
      <div className="content">
        <img src={iconPath} alt="" style={{ marginRight: "16px" }} />
        <p className="font">{title}</p>
      </div>
      <form>
        {console.log(dataKey)}
        <input
          className="input"
          type="number"
          placeholder={placeholder}
          // value={data[dataKey]}
          // onChange={(e) => handleOnChange(e.target.value)}
          required
          // step3 register your selected input
          {...register(dataKey, { required: true })}
        />
        {/* step4 check if there is an error */}
        {errors[dataKey] && (
          <p className="error" style={{ color: "red" }}>
            This field is required
          </p>
        )}
      </form>
    </div>
  );
}

function HotelInformation({ data, setData }: any) {
  const { setCount, count } = useContext(DataContext);

  //Step1: import useform()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      commonSpace: data["commonSpace"],
      entrance: data["entrance"],
      guestRoom: data["guestRoom"],
      noOfFloor: data["noOfFloor"],
    },
  });
  const formsList: FormListProps[] = [
    {
      key: "commonSpace",
      title: "Common space [m^2]",
      placeholder: "Enter your common space",
      iconPath: RoomIcon,
    },
    {
      key: "entrance",
      title: "Entrances [no. of entrances]",
      placeholder: "Enter your hotel entrance",
      iconPath: TitleIcon,
    },
    {
      key: "guestRoom",
      title: "Guest room [no. of rooms]",
      placeholder: "Enter a number of guest room",
      iconPath: FloorIcon,
    },
    {
      key: "noOfFloor",
      title: "No. of Floor [no. of floors]",
      placeholder: "Enter a number of floor",
      iconPath: ApplicationIcon,
    },
  ];

  // step6 handle submit action from Form
  const onSubmit = (e: any) => {
    setCount(count + 1);
    // step7 bind your form data to the state("data")
    setData(e);
  };

  return (
    <div className="form-container">
      {/* step5 register your form with handleSubmit fn */}
      <form className="quotation-form" onSubmit={handleSubmit(onSubmit)}>
        <FormTitle subtitleBold={"Hotel Information"} subtitle={"Enter your hotel information."} />
        {formsList.map((item) => (
          <>
            <Form
              key={item.key}
              title={item.title}
              placeholder={item.placeholder}
              iconPath={item.iconPath}
              dataKey={item.key}
              data={data}
              setData={setData}
              // step2 pass register and errors to the form component
              register={register}
              errors={errors}
            />
          </>
        ))}
        <div className="itemend">
          <button className="but" type="submit">
            <p className="next">next</p>
            <img src={ForwardIcon} alt="" />
          </button>
        </div>
      </form>
    </div>
  );
}

export default HotelInformation;
