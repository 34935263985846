import "./FormsComponent.css";
import ForwardIcon from "../assets/common/ForwardIcon.svg";
import ReturnIcon from "../assets/common/ReturnIcon.svg";
import { useContext } from "react";
import { DataContext } from "../App";
import { FormTitle } from "./common/FormTitle";

function HvacType({
  setHVACCS,
  choiceCS,
  setChoiceCS,
  choiceRoom,
  setChoiceRoom,
  setHVACRoom,
}: any) {
  const { setCount, count } = useContext(DataContext);
  const handleSelectDropdownCS = (data: any) => {
    setChoiceCS(data.target.value);
  };
  const handleSelectDropdownRoom = (data: any) => {
    setChoiceRoom(data.target.value);
  };
  return (
    <div className="form-container">
      <form className="quotation-form">
        <FormTitle
          subtitleBold={"HVAC Type"}
          subtitle={"Choose your HVAC type."}
        />
        <div className="formrow">
          <div className="content">
            <p className="font">Select your HVAC Common Space type</p>
          </div>
          <form>
            <select
              className="select"
              name="bill"
              id="bill"
              onChange={handleSelectDropdownCS}
            >
              <option value="Chiller [FCU]" label="Chiller"></option>
              <option value="VRF / VRV [FCU]" label="VRF / VRV"></option>
              <option value="Split Type [Unit]" label="Split Type"></option>
            </select>
          </form>
        </div>
        <div className="formrow">
          <div className="content">
            <p className="font">{choiceCS}</p>
          </div>
          <form>
            <input
              className="input"
              type="number"
              placeholder={choiceCS}
              onChange={(e) => setHVACCS(e.target.value)}
            />
          </form>
        </div>
        <div className="formrow">
          <div className="content">
            <p className="font">Select your HVAC Room type</p>
          </div>
          <form>
            <select
              className="select"
              name="bill"
              id="bill"
              onChange={handleSelectDropdownRoom}
            >
              <option value="Chiller [FCU]" label="Chiller"></option>
              <option value="VRF / VRV [FCU]" label="VRF / VRV"></option>
              <option value="Split Type [Unit]" label="Split Type"></option>
            </select>
          </form>
        </div>
        <div className="formrow">
          <div className="content">
            <p className="font">{choiceRoom}</p>
          </div>
          <form>
            <input
              className="input"
              type="number"
              placeholder={choiceRoom}
              onChange={(e) => setHVACRoom(e.target.value)}
            />
          </form>
        </div>
      </form>
      <div className="box">
        <div className="itemstart">
          <button
            type="button"
            className="but"
            onClick={() => setCount(count - 1)}
          >
            <img src={ReturnIcon} alt="" />
            <p className="next">back</p>
          </button>
        </div>
        <div className="itemend">
          <button
            type="button"
            className="but"
            onClick={() => setCount(count + 1)}
          >
            <p className="next">next</p>
            <img src={ForwardIcon} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default HvacType;
