import { useContext } from "react";
import { DataContext } from "../App";
import logo from "../assets/logo/Logo-01-2 1.png";
import "./StepComponent.css";
import CheckIcon from "../assets/common/CheckIcon.svg";

type StepListProps = {
  number: number;
  title: string;
};

type StepProps = {
  number: number;
  title: string;
  status: "current" | "prev" | "next";
};

type GetStepStatusFunction = (
  count: number,
  index: number
) => "current" | "prev" | "next";

function Step({ number, title, status }: StepProps) {
  return (
    <div className="steprow">
      <div className={status + "ellipse8"}>
        {status === "prev" ? (
          <img src={CheckIcon} alt="" />
        ) : (
          <p className={status + "n1"}>{number}</p>
        )}
      </div>
      <p className={status + "steptitle"}>{title}</p>
    </div>
  );
}

function StepsComponent() {
  const { count } = useContext(DataContext);

  const stepsList: StepListProps[] = [
    { number: 1, title: "Hotel Information" },
    { number: 2, title: "Energy Historical" },
    { number: 3, title: "HVAC Type" },
    { number: 4, title: "Energy Analytics" },
    { number: 5, title: "Investment" },
  ];

  const getStepStatus: GetStepStatusFunction = (count, index) => {
    let result: "current" | "prev" | "next" = "current";
    if (count > index) {
      result = "prev";
    } else if (count < index) {
      result = "next";
    }
    return result;
  };

  return (
    <div className="left-container">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="Logo" />
        <h3 className="alto">AltoTech</h3>
      </div>
      <div className="step-container">
        {stepsList.map((item, index) => (
          <Step
            status={getStepStatus(count, index)}
            number={item.number}
            title={item.title}
          />
        ))}
      </div>
    </div>
  );
}

export default StepsComponent;
