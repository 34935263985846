import React from "react";
import { useTable } from "react-table";

function TestComponent(result2: any, result3: any) {
  console.log({ result2, result3 });
  console.log(result2.result2);
  const x: string = result2.result2.toFixed(2).toString();
  const y: string = result2.result3.toFixed(2).toString();
  const data = React.useMemo(
    () => [
      {
        col1: "Energy Use Index",
        col2: "271",
        col3: "199",
        col4: "160",
        col5: "116",
        col6: "97",
        col7: x,
      },
      {
        col1: "Carbon footprint",
        col2: "132.03",
        col3: "96.95",
        col4: "77.95",
        col5: "56.52",
        col6: "47.26",
        col7: y,
      },
      // {
      //   col1: 'react-table',
      //   col2: 'rocks',
      // },
      // {
      //   col1: 'whatever',
      //   col2: 'you want',
      // },
    ],
    [x, y]
  );

  const getOrderedHeader = (value: string) => {
    console.log(value);
    let newValue = Number(value);
    console.log(newValue);
    const addedBuilding = {
      Header: "Your building",
      accessor: "col7",
    };
    let newIndex = 0;
    let headers = [
      {
        Header: "Target Year",
        accessor: "col1", // accessor is the "key" in the data
      },
      {
        Header: "2015",
        accessor: "col2",
      },
      {
        Header: "2019",
        accessor: "col3",
      },
      {
        Header: "2025",
        accessor: "col4",
      },
      {
        Header: "2031",
        accessor: "col5",
      },
      {
        Header: "2036",
        accessor: "col6",
      },
    ];

    if (newValue) {
      [271, 199, 160, 116, 97].forEach((value: number, index: number) => {
        if (newValue < value) {
          newIndex = index + 1;
          console.log(newIndex);
        }
      });
    }
    console.log({ x, newIndex });
    headers.splice(newIndex + 1, 0, addedBuilding);
    return headers;
  };
  console.log(getOrderedHeader(x));
  const columns = React.useMemo(() => getOrderedHeader(x), []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable<any>({ columns, data });

  return (
    <table
      {...getTableProps()}
      style={{ border: "solid 2px #ffffff", borderRadius: "16px" }}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps()}
                style={{
                  padding: "20px",
                  borderBottom: "solid 3px #e5e7eb",
                  background: "aliceblue",
                  color: "black",
                  fontWeight: "bold",
                }}
              >
                {column.render("Header")}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    style={{
                      padding: "20px",
                      border: "solid 1px gray",
                      background: "white",
                    }}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default TestComponent;
