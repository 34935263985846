import "./FormsComponent.css";
import ForwardIcon from "../assets/common/ForwardIcon.svg";
import ReturnIcon from "../assets/common/ReturnIcon.svg";
import { useContext } from "react";
import { DataContext } from "../App";
import TestComponent from "./TestComponent";
import { FormTitle } from "./common/FormTitle";
// import { useTable } from "react-table";

function EnergyAnalytics({ data, energyData, choice, setChoice }: any) {
  const { setCount, count } = useContext(DataContext);
  const formatNumber = (num: any) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };
  const totalArea = parseInt(data.commonSpace) + parseInt(data.guestRoom) * 30;
  const result1: number =
    choice === "Average monthly Electricity Bill [baht]"
      ? (parseInt(energyData) / 3.4812) * 12
      : parseInt(energyData) * 12;
  const result2: number = result1 / totalArea;
  const result3: number = result2 * 0.4872;
  return (
    <div className="form-container">
      <FormTitle
        subtitleBold={"Energy Analytics"}
        subtitle={"our result energy analytics."}
      />
      <form className="quotation-form">
        <div className="formrow">
          <div className="content2">
            <p className="font">Yearly electricity consumption</p>
            <p className="shownum">{formatNumber(result1.toFixed(2))}</p>
            <p className="font">kWh</p>
          </div>
        </div>
        <div className="formrow">
          <div className="content2">
            <p className="font">Energy Use Index</p>
            <p className="shownum">{formatNumber(result2.toFixed(2))}</p>
            <p className="font">kWh/m2/year</p>
          </div>
        </div>
        <div className="formrow">
          <div className="content2">
            <p className="font">Carbon footprint per square meter</p>
            <p className="shownum">{formatNumber(result3.toFixed(2))}</p>
            <p className="font">kgCO2e/m2/year</p>
          </div>
        </div>
        <div className="formrow">
          <TestComponent result2={result2} result3={result3} />
        </div>
      </form>
      <div className="box">
        <div className="itemstart">
          <button
            type="button"
            className="but"
            onClick={() => setCount(count - 1)}
          >
            <img src={ReturnIcon} alt="" />
            <p className="next">back</p>
          </button>
        </div>
        <div className="itemend">
          <button
            type="button"
            className="but"
            onClick={() => setCount(count + 1)}
          >
            <p className="next">next</p>
            <img src={ForwardIcon} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default EnergyAnalytics;
