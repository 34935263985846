import "./FormsComponent.css";
import ForwardIcon from "../assets/common/ForwardIcon.svg";
import ReturnIcon from "../assets/common/ReturnIcon.svg";
import { useContext } from "react";
import { DataContext } from "../App";
import { FormTitle } from "./common/FormTitle";

function Investment({ data, energyData, HVACCS, HVACRoom }: any) {
  const { setCount, count } = useContext(DataContext);
  const formatNumber = (num: any) => {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  };
  // SoftwareRoom and SoftwareCommonSpace Calculate
  let SoftwareRoom: number = 0;
  if (parseInt(data.guestRoom) > 0 && parseInt(data.guestRoom) <= 50) {
    SoftwareRoom = parseInt(data.guestRoom) * 9000;
  } else if (parseInt(data.guestRoom) > 50 && parseInt(data.guestRoom) <= 150) {
    SoftwareRoom = (parseInt(data.guestRoom) - 50) * 8400 + 450000;
  } else if (
    parseInt(data.guestRoom) > 150 &&
    parseInt(data.guestRoom) <= 300
  ) {
    SoftwareRoom = (parseInt(data.guestRoom) - 150) * 7200 + 1290000;
  } else if (
    parseInt(data.guestRoom) > 300 &&
    parseInt(data.guestRoom) <= 500
  ) {
    SoftwareRoom = (parseInt(data.guestRoom) - 300) * 6000 + 2370000;
  } else if (parseInt(data.guestRoom) > 500) {
    SoftwareRoom = (parseInt(data.guestRoom) - 500) * 4500 + 3570000;
  }
  let SoftwareCS: number = 0;
  if (parseInt(data.commonSpace) > 0 && parseInt(data.commonSpace) <= 1500) {
    SoftwareCS = parseInt(data.commonSpace) * 300;
  } else if (
    parseInt(data.commonSpace) > 1500 &&
    parseInt(data.commonSpace) <= 4500
  ) {
    SoftwareCS = (parseInt(data.commonSpace) - 1500) * 280 + 450000;
  } else if (
    parseInt(data.commonSpace) > 4500 &&
    parseInt(data.commonSpace) <= 9000
  ) {
    SoftwareCS = (parseInt(data.commonSpace) - 4500) * 240 + 1290000;
  } else if (
    parseInt(data.commonSpace) > 9000 &&
    parseInt(data.commonSpace) <= 15000
  ) {
    SoftwareCS = (parseInt(data.commonSpace) - 9000) * 200 + 2370000;
  } else if (parseInt(data.commonSpace) > 15000) {
    SoftwareCS = (parseInt(data.commonSpace) - 15000) * 150 + 3570000;
  }
  // test
  console.log({ SoftwareRoom, SoftwareCS });
  // Software and Cloud Calculate
  const Software = SoftwareRoom + SoftwareCS;
  const Sqm = parseInt(data.commonSpace) * 60 + parseInt(data.guestRoom) * 1800;
  const CCTV = (parseInt(data.entrance) + parseInt(data.noOfFloor) * 4) * 240;
  const Cloud = Sqm + CCTV;
  const totalSoftwareCloud = Software + Cloud;
  const FinalSoftwareCloud = (totalSoftwareCloud * 140) / 100;
  // Hardware Calculate
  const IoTGateway = Math.ceil(parseInt(data.noOfFloor) / 5) * 40000;
  const AIGateway =
    (parseInt(data.entrance) + parseInt(data.noOfFloor) * 4) * 5250;
  const EnvSensorCO2 = (parseInt(HVACCS) + parseInt(HVACRoom)) * 5490;
  const SmartMeter = parseInt(data.guestRoom) * 2290;
  const Zigbee = parseInt(data.guestRoom) * 1290;
  const Hardware =
    IoTGateway + AIGateway + EnvSensorCO2 + SmartMeter + Zigbee + 7000;
  // Onsite Energy Audit Calculate
  const OnsiteEnergyAudit = parseInt(data.guestRoom) * 600 + 18000;
  const Total = FinalSoftwareCloud + Hardware + OnsiteEnergyAudit;

  return (
    <div className="form-container">
      <FormTitle subtitleBold={"Investment"} subtitle={"Your Investment."} />
      <form className="quotation-form">
        <div className="formrow">
          <div className="content2">
            <p className="font">Software + Cloud</p>
            <p className="shownum">
              {formatNumber(FinalSoftwareCloud.toFixed(2))}
            </p>
            <p className="font">THB</p>
          </div>
        </div>
        <div className="formrow">
          <div className="content2">
            <p className="font">Hardware</p>
            <p className="shownum">{formatNumber(Hardware.toFixed(2))}</p>
            <p className="font">THB</p>
          </div>
        </div>
        <div className="formrow">
          <div className="content2">
            <p className="font">Onsite Energy Audit</p>
            <p className="shownum">
              {formatNumber(OnsiteEnergyAudit.toFixed(2))}
            </p>
            <p className="font">THB</p>
          </div>
        </div>
        <div className="formrow">
          <div className="content2">
            <p className="font">Total</p>
            <p className="shownum">{formatNumber(Total.toFixed(2))}</p>
            <p className="font">THB</p>
          </div>
        </div>
      </form>
      <div className="box">
        <div className="itemstart">
          <button
            type="button"
            className="but"
            onClick={() => setCount(count - 1)}
          >
            <img src={ReturnIcon} alt="" />
            <p className="next">back</p>
          </button>
        </div>
        <div className="itemend">
          <button
            type="button"
            className="but"
            onClick={() => setCount(count + 1)}
          >
            <p className="next">next</p>
            <img src={ForwardIcon} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default Investment;
