import { createContext, useState } from "react";
import "./App.css";
import "./main.css";

import StepsComponent from "./components/StepsComponent";
import HotelInformation from "./components/HotelInformation";
import EnergyHistory from "./components/EnergyHistory";
import HvacType from "./components/HvacType";
import EnergyAnalytics from "./components/EnergyAnalytics";
import Investment from "./components/Investment";

import { useForm } from "react-hook-form";

export const DataContext = createContext<any>({});

const Parent = () => {
  const [count, setCount] = useState(0);
  const [data, setData] = useState<any>({
    commonSpace: "",
    entrance: "",
    guestRoom: "",
    noOfFloor: "",
  });
  const [energyData, setEnergyData] = useState(0);
  const [choice, setChoice] = useState("Average monthly Electricity Bill [baht]");
  const [choiceCS, setChoiceCS] = useState("Chiller [FCU]");
  const [HVACCS, setHVACCS] = useState(0);
  const [choiceRoom, setChoiceRoom] = useState("Chiller [FCU]");
  const [HVACRoom, setHVACRoom] = useState(0);

  return (
    <DataContext.Provider value={{ count, setCount }}>
      <div className="app-container">
        <div className="component-container">
          <StepsComponent />

          {count === 0 && <HotelInformation data={data} setData={setData} />}
          {count === 1 && <EnergyHistory energyData={energyData} setEnergyData={setEnergyData} choice={choice} setChoice={setChoice} />}
          {count === 2 && (
            <HvacType
              setHVACCS={setHVACCS}
              choiceCS={choiceCS}
              setChoiceCS={setChoiceCS}
              choiceRoom={choiceRoom}
              setChoiceRoom={setChoiceRoom}
              setHVACRoom={setHVACRoom}
            />
          )}
          {count === 3 && <EnergyAnalytics data={data} energyData={energyData} choice={choice} setChoice={setChoice} />}
          {count === 4 && <Investment data={data} energyData={energyData} HVACCS={HVACCS} HVACRoom={HVACRoom} />}
        </div>
      </div>
    </DataContext.Provider>
  );
};

function App() {
  return <Parent />;
}

export default App;
